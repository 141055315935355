<template>
  <main class="page-container" id="sme-signup-completed">
    <div class="auth-component">
      <header class="auth-header">
        <h2 class="title">회원가입</h2>
      </header>
      <div class="auth-body">
        <div class="auth-content">
          <h3 class="text">회원가입이 완료되었어요 👏🏻</h3>
        </div>
        <div class="auth-buttons wide">
          <button class="btn btn-xl btn-primary" @click="clickLogin">
            <span class="text">로그인</span>
          </button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import axios from 'axios'
import {ACT_INSERT_CASHFRIENDS_LOG} from '../../../store/_act_consts';

export default {
  name: 'SignupComplete',
  data: ()=>({
    callbackParams : '',             //캐시프랜즈 API callbackParams 선언
    eventName : 'valuebuy_signup',   //캐시프렌즈 API 전달 이벤트명
    accessToken : 'isUTTJMx',        //캐시프렌즈 API 토큰
    lgnId : '',                      //캐시프랜즈 LOG저장 로그인 아이디
  }),
  created() {
    this.callbackParams = this.$route.params.callbackParams;
    this.lgnId = this.$route.params.lgnId;
    
    if(this.callbackParams) this.submitForm() //callbackParams null값이 아닌경우 캐시프렌즈에 데이터 전송
    
    //구글 애널리틱스 임시 주석 처리 (20220808 hib)
    //gtag('event', 'conversion', {'send_to': 'AW-10930808960/PFaUCL7P8cQDEIDRm9wo'});
  },
  methods: {
    clickLogin(){ 
      this.$router.push({name: 'Login'});
    },
    // 캐시프랜즈 API POSTBACK 호출
    async submitForm(){
      
      let returnRes = '';   // 캐시프랜즈 성공여부 저장변수
      let resCd = ''; // 캐시프랜즈 성공여부 코드값 저장변수
      const url = 'https://trx.cashfriends.io/postback/trevi_czS96v0i/cashfriends?callbackParams='+encodeURI(this.callbackParams)+'&eventName='+this.eventName+'&accessToken='+this.accessToken;

      //캐시프랜즈 포스트백 호출
      await axios.get(url).then(function(response){
        returnRes = 'SUCCESS';
        resCd = response.status;
      }).catch(function(error){
        returnRes = 'FAIL';
        resCd = error.response.status;
      });

      if(returnRes === 'SUCCESS'){
        this.$store.dispatch(`auth/${ACT_INSERT_CASHFRIENDS_LOG}`, {lgnId: this.lgnId, clbParam: this.callbackParams, secYn: 'Y', resCd: resCd})
      }else if(returnRes === 'FAIL'){
        this.$store.dispatch(`auth/${ACT_INSERT_CASHFRIENDS_LOG}`, {lgnId: this.lgnId, clbParam: this.callbackParams, secYn: 'N', resCd: resCd})
      }
    },
  }
};
</script>